import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { getBoard } from '../utils/api/boards';
import NotFoundPage from '../pages/NotFoundPage';

export function ProtectedRoute({ children }) {
  const { token } = useAuth();
  const [finished, setFinished] = useState(false);
  const [elementsToRender, setElementsToRender] = useState();
  const navigate = useNavigate();

  const validationChecks = async () => {
    const currentUrl = window.location.pathname;
    const boardPageRegex = /^\/board\/\d+$/;
    if (boardPageRegex.test(currentUrl)) {
      const boardId = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
      const boardResponse = await getBoard(token, boardId);
      return Math.floor(boardResponse.status / 100) !== 4;
    }
    if (!token) {
      navigate('/auth', { replace: true });
    }
    return true;
  };

  useEffect(() => {
    setElementsToRender(children);
    setFinished(false);
  }, [window.location.href]);

  useEffect(() => {
    validationChecks().then((response) => {
      if (!response) {
        setElementsToRender(<NotFoundPage />);
      }

      setFinished(true);
    });
  }, [window.location.href]);

  if (!finished) {
    return <div />;
  }

  return elementsToRender;
}
