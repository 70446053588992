import { useEffect, useState } from 'react';
import { Loading } from 'react-daisyui';
import Collection from '../components/Collection';
import { getCollectionById } from '../utils/api/collections';

export default function AnonymousPage() {
  const [collection, setCollection] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const collectionId = window.location.pathname.split('/').pop();
    getCollectionById(collectionId).then((response) => {
      setCollection(response.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="container px-4 mt-4">
      {isLoading && (
        <div className="flex flex-col h-full w-full mt-14 justify-center items-center intro-animation">
          <Loading variant="dots" size="lg" />
          <div>Loading data...</div>
        </div>
      )}
      {!isLoading && (
        <div>
          <Collection
            id={collection?.id}
            name={collection?.name}
            boards={collection?.boards}
            isShared={collection?.isShared}
            displaySharedLabel
            author={collection?.authorId}
            role={collection?.role}
          />
        </div>
      )}
    </div>
  );
}
